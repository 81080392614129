import { useStaticQuery, graphql } from 'gatsby';

export const useHomepagePromo2Data = () => {
  const { taxonomyTermPromotion } = useStaticQuery(
    graphql`
      query HomepagePromo2Data {
        taxonomyTermPromotion(drupal_internal__tid: { eq: 175 }) {
          relationships {
            field_promoted_content {
              ... on node__event {
                id
                title
                body {
                  summary
                }
                srcSet: field_image {
                  ...eventSrcSet16x9Fragment
                  ...eventSrcSet10x7Fragment
                }
                field_image {
                  imageDerivatives {
                    links {
                      w344_16_9 {
                        href
                      }
                    }
                  }
                  alt
                }
                relationships {
                  type: node_type {
                    name
                  }
                  field_tags {
                    relationships {
                      parent {
                        name
                      }
                    }
                    name
                  }
                }
                path {
                  alias
                }
              }
              ... on node__news {
                id
                title
                body {
                  summary
                }
                srcSet: field_image {
                  ...newsSrcSet16x9Fragment
                  ...newsSrcSet10x7Fragment
                }
                field_image {
                  imageDerivatives {
                    links {
                      w344_16_9 {
                        href
                      }
                    }
                  }
                  alt
                }
                relationships {
                  type: node_type {
                    name
                  }
                  field_tags {
                    relationships {
                      parent {
                        name
                      }
                    }
                    name
                  }
                }
                path {
                  alias
                }
              }
              ... on node__page {
                id
                title
                body {
                  summary
                }
                srcSet: field_image {
                  ...pageSrcSet16x9Fragment
                  ...pageSrcSet10x7Fragment
                }
                field_image {
                  imageDerivatives {
                    links {
                      w344_16_9 {
                        href
                      }
                    }
                  }
                  alt
                }
                relationships {
                  type: node_type {
                    name
                  }
                  field_tags {
                    relationships {
                      parent {
                        name
                      }
                    }
                    name
                  }
                }
                path {
                  alias
                }
              }
              ... on node__place {
                id
                title
                body {
                  summary
                }
                srcSet: field_image {
                  ...placeSrcSet16x9Fragment
                  ...placeSrcSet10x7Fragment
                }
                field_image {
                  imageDerivatives {
                    links {
                      w344_16_9 {
                        href
                      }
                    }
                  }
                  alt
                }
                relationships {
                  type: node_type {
                    name
                  }
                  field_tags {
                    relationships {
                      parent {
                        name
                      }
                    }
                    name
                  }
                }
                path {
                  alias
                }
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  let promo2query =
    taxonomyTermPromotion.relationships.field_promoted_content[0];
  const ratio16x9links = promo2query?.srcSet?.ratio16x9?.links ?? {};
  const ratio16x9 = ratio16x9links
    ? Object.keys(ratio16x9links).map((value) => ratio16x9links[value].href)
    : [];

  const homepagePromo2Data = {
    title: promo2query?.title,
    id: promo2query?.id,
    type: promo2query?.relationships?.type?.name,
    summary: promo2query?.body?.summary,
    url: promo2query?.path?.alias,
    parentTag:
      promo2query?.relationships?.field_tags[0]?.relationships?.parent[0]?.name,
    tag: promo2query?.relationships?.field_tags[0]?.name,
    srcSet: {
      alt: promo2query?.field_image?.alt,
      srcs: {
        ratio16x9
      }
    },
    largeImage: {
      alt: promo2query?.field_image?.alt,
      // eslint-disable-next-line camelcase
      src: promo2query?.field_image?.imageDerivatives?.links?.w344_16_9?.href
    },
    image: {
      alt: promo2query?.field_image?.alt,
      // eslint-disable-next-line camelcase
      src: promo2query?.field_image?.imageDerivatives?.links?.w344_16_9?.href
    }
  };

  return homepagePromo2Data;
};
