import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

import styles from './hero-slide.module.scss';

const HeroSlide = ({
  title,
  body,
  url,
  type,
  images,
  cardSummaryOnly,
  tag,
  parentTag
}) => (
  <section className={styles.slide} aria-label={title}>
    <div className={styles.imgContainer}>
      {images && (
        <>
          <img
            src={images.square}
            className={styles.largeImage}
            alt={images.alt}
          />
          <img
            src={images.rect}
            className={styles.smallImage}
            alt={images.alt}
          />
        </>
      )}
    </div>
    <div className={styles.sliderContent}>
      <p className={styles.eyebrow}>{parentTag ? parentTag : tag}</p>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.body}>
        {body.length > 250 ? `${body.substring(0, 250)}...` : body}
      </div>
      <div className={styles.meta}>
        <div className={styles.metaInner}>
          {!cardSummaryOnly && (
            <Button
              className={styles.button}
              to={url}
              textStyle="upper"
              aria-label={`Read more about ${title}`}
            >
              {type === 'Place' && 'Learn More'}
              {type === 'News' && 'Read More'}
              {type === 'Event' && 'View Event'}
              {type === 'Page' && 'View Page'}
            </Button>
          )}
        </div>
      </div>
    </div>
  </section>
);

HeroSlide.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  images: PropTypes.object,
  cardSummaryOnly: PropTypes.bool,
  tag: PropTypes.string,
  parentTag: PropTypes.string
};

export default HeroSlide;
