import { useStaticQuery, graphql } from 'gatsby';

export const useProgramsData = () => {
  const { allNodeEvent } = useStaticQuery(
    graphql`
      query ProgramsData {
        allNodeEvent(
          filter: {
            relationships: {
              field_tags: {
                elemMatch: {
                  relationships: {
                    parent: {
                      elemMatch: { name: { eq: "Recreational Program" } }
                    }
                  }
                }
              }
            }
            isOver: { eq: false }
          }
          sort: { fields: field_date___value, order: ASC }
        ) {
          nodes {
            title
            path {
              alias
            }
            field_date {
              value(formatString: "dddd, MMMM D")
              format
            }
            field_image {
              imageDerivatives {
                links {
                  w90_1_1 {
                    href
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const programs = allNodeEvent.nodes.map((item) => {
    return {
      title: item?.title,
      startDate: item?.field_date.value,
      formattedDate: item?.field_date.format,
      smallImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w90_1_1?.href
      },
      url: item?.path?.alias
    };
  });

  return programs;
};
