import { useStaticQuery, graphql } from 'gatsby';

export const usePromotedPageData = () => {
  const { nodePage } = useStaticQuery(
    graphql`
      query PromotedPageData {
        nodePage(id: { eq: "c9ea092c-607f-546b-9ec4-d50968acce4a" }) {
          title
          body {
            processed
          }
          path {
            alias
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const promotedPage = {
    title: nodePage?.title,
    body: nodePage?.body?.processed,
    url: nodePage?.path?.alias
  };

  return promotedPage;
};
