import { useStaticQuery, graphql } from 'gatsby';

export const usePromotionsData = () => {
  const { allTaxonomyTermPromotion } = useStaticQuery(
    graphql`
      query PromotionsData {
        allTaxonomyTermPromotion(
          filter: { drupal_internal__tid: { eq: 137 } }
        ) {
          nodes {
            relationships {
              field_promoted_content {
                ... on node__event {
                  id
                  internal {
                    type
                  }
                  title
                  path {
                    alias
                  }
                  field_date {
                    end: end_value
                  }
                  field_image {
                    alt
                    imageDerivatives {
                      links {
                        w322_1_1 {
                          href
                        }
                        w344_16_9 {
                          href
                        }
                      }
                    }
                  }
                  body {
                    summary
                  }
                  relationships {
                    type: node_type {
                      name
                    }
                    field_tags {
                      relationships {
                        parent {
                          name
                        }
                      }
                      name
                    }
                  }
                }
                ... on node__news {
                  id
                  internal {
                    type
                  }
                  title
                  field_date {
                    end: end_value
                  }
                  field_image {
                    alt
                    imageDerivatives {
                      links {
                        w344_16_9 {
                          href
                        }
                        w322_1_1 {
                          href
                        }
                      }
                    }
                  }
                  path {
                    alias
                  }
                  body {
                    summary
                  }
                  relationships {
                    type: node_type {
                      name
                    }
                    field_tags {
                      relationships {
                        parent {
                          name
                        }
                      }
                      name
                    }
                  }
                  field_summary_only
                }
                ... on node__page {
                  id
                  internal {
                    type
                  }
                  title
                  field_date {
                    end: end_value
                  }
                  field_image {
                    imageDerivatives {
                      links {
                        w322_1_1 {
                          href
                        }
                        w344_16_9 {
                          href
                        }
                      }
                    }
                    alt
                  }
                  path {
                    alias
                  }
                  body {
                    summary
                  }
                  relationships {
                    type: node_type {
                      name
                    }
                    field_tags {
                      relationships {
                        parent {
                          name
                        }
                      }
                      name
                    }
                  }
                }
                ... on node__place {
                  id
                  internal {
                    type
                  }
                  title
                  field_date {
                    end: end_value
                  }
                  field_image {
                    imageDerivatives {
                      links {
                        w322_1_1 {
                          href
                        }
                        w344_16_9 {
                          href
                        }
                      }
                    }
                    alt
                  }
                  path {
                    alias
                  }
                  body {
                    summary
                  }
                  relationships {
                    type: node_type {
                      name
                    }
                    field_tags {
                      relationships {
                        parent {
                          name
                        }
                      }
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const currentDate = new Date();
  const currentPromos = allTaxonomyTermPromotion.nodes[0].relationships.field_promoted_content.filter(
    // Only include nodes that occur in the future.
    (node) => {
      if (
        node.internal.type === 'node__event' ||
        node.internal.type === 'node__news' ||
        node.internal.type === 'node__page' ||
        node.internal.type === 'node__place'
      ) {
        if (node.field_date !== undefined) {
          let dater = node.field_date;
          return dater && dater.end && new Date(dater.end) > currentDate;
        }
      } else {
        return true;
      }
    }
  );
  const promotions = currentPromos.map((item) => {
    return {
      title: item.title,
      body: item?.body?.summary,
      cardSummaryOnly: item?.field_summary_only,
      images: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        rect: item.field_image?.imageDerivatives?.links?.w344_16_9?.href,
        // eslint-disable-next-line camelcase
        square: item.field_image?.imageDerivatives?.links?.w322_1_1?.href
      },
      type: item?.relationships?.type?.name,
      parentTag:
        item?.relationships?.field_tags[0]?.relationships?.parent[0]?.name,
      tag: item?.relationships?.field_tags[0]?.name,
      url: item?.path?.alias,
      expired: item?.field_date?.end
    };
  });
  return promotions;
};
