import React from 'react';
import PropTypes from 'prop-types';

import HeroSlide from '../hero-slide';
import HeroSlider from '../hero-slider';
import styles from './hero.module.scss';

const Hero = ({ promotions }) => {
  return (
    <section className={styles.hero}>
      <HeroSlider>
        {promotions.map((slide, index) => {
          return <HeroSlide key={index} {...slide} images={slide.images} />;
        })}
      </HeroSlider>
    </section>
  );
};

Hero.propTypes = {
  promotions: PropTypes.array.isRequired
};

export default Hero;
