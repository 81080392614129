import { useStaticQuery, graphql } from 'gatsby';

export const useTallPromotedData = () => {
  const { nodePage } = useStaticQuery(
    graphql`
      query TallPromotedData {
        nodePage(id: { eq: "6c454780-6148-5d5b-b752-6c2f0f148899" }) {
          title
          body {
            processed
          }
          path {
            alias
          }
          field_webpage {
            title
            uri
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.

  const tallPromoted = {
    title: nodePage?.title,
    body: nodePage?.body?.processed,
    url: nodePage?.path?.alias,
    moreLink: {
      url: nodePage?.field_webpage?.uri,
      title: nodePage?.field_webpage?.title
    }
  };
  return tallPromoted;
};
