import { useStaticQuery, graphql } from 'gatsby';

export const useCityEventsData = () => {
  const { allNodeEvent } = useStaticQuery(
    graphql`
      query CityEventsData {
        allNodeEvent(
          filter: {
            relationships: {
              field_tags: {
                elemMatch: {
                  relationships: {
                    parent: {
                      elemMatch: { name: { eq: "City Springs Event" } }
                    }
                  }
                }
              }
            }
            isOver: { eq: false }
          }
          limit: 4
          sort: { fields: field_date___value, order: ASC }
        ) {
          nodes {
            title
            body {
              summary
            }
            path {
              alias
            }
            relationships {
              field_tags {
                name
                relationships {
                  parent {
                    name
                  }
                }
              }
              type: node_type {
                name
              }
            }
            field_date {
              value(formatString: "dddd, MMMM D")
              format
            }
            dateShort: field_date {
              value(formatString: "MM/DD")
            }
            srcSet: field_image {
              ...eventSrcSet16x9Fragment
              ...eventSrcSet10x7Fragment
            }
            field_image {
              imageDerivatives {
                links {
                  w90_1_1 {
                    href
                  }
                  w344_16_9 {
                    href
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const cityEvents = allNodeEvent.nodes.map((item) => {
    const ratio16x9links = item?.srcSet?.ratio16x9?.links ?? {};
    const ratio16x9 = ratio16x9links
      ? Object.keys(ratio16x9links).map((value) => ratio16x9links[value].href)
      : [];
    return {
      title: item?.title,
      summary: item?.body.summary,
      type: item?.relationships?.type?.name,
      startDate: item?.field_date.value,
      formattedDate: item?.field_date.format,
      dateShort: item?.dateShort?.value,
      tag: item?.relationships?.field_tags[0]?.name,
      parentTag:
        item?.relationships?.field_tags[0]?.relationships?.parent[0]?.name,
      smallImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w90_1_1?.href
      },
      largeImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w344_16_9?.href
      },
      srcSet: {
        alt: item?.field_image?.alt,
        srcs: {
          ratio16x9
        }
      },
      url: item?.path?.alias
    };
  });

  return cityEvents;
};
