import React from 'react';
import PropTypes from 'prop-types';
import WYSIWYG from '../wysiwyg';

// Component styles.
import styles from './promoted-page.module.scss';

const PromotedPage = ({ page, dangerouslyRender }) => {
  return (
    <div className={styles.promotedCard}>
      <div className={styles.promotedTitle}>{page.title}</div>
      <WYSIWYG
        className={styles.promotedBody}
        body={page.body}
        dangerouslyRender={dangerouslyRender}
      />
    </div>
  );
};

PromotedPage.propTypes = {
  page: PropTypes.object,
  dangerouslyRender: PropTypes.bool
};

export default PromotedPage;
