import { useStaticQuery, graphql } from 'gatsby';

export const useSuggestionFormData = () => {
  const { nodePage } = useStaticQuery(
    graphql`
      query SuggestionFormData {
        nodePage(id: { eq: "8fdf052c-cef9-54ae-8879-bc43b8316271" }) {
          title
          body {
            processed
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const suggestionForm = {
    title: nodePage?.title,
    body: nodePage?.body?.processed
  };

  return suggestionForm;
};
