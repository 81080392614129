import React from 'react';
import PropTypes from 'prop-types';
import WYSIWYG from '../wysiwyg';
import Link from '../link';

// Component styles.
import styles from './tall-promoted-page.module.scss';

const TallPromotedPage = ({ page }) => (
  <div className={styles.tallCard}>
    <div className={styles.tallCardHeader}>
      <div className={styles.tallCardTitle}>{page.title}</div>
      <Link className={styles.moreLink} to={page.url}>
        {page.moreLink.title}
      </Link>
    </div>
    <WYSIWYG className={styles.promotedBody} body={page.body} />
  </div>
);

TallPromotedPage.propTypes = {
  page: PropTypes.object
};

export default TallPromotedPage;
