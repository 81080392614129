import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// Imports the CSS from slick carousel.
import 'slick-carousel/slick/slick.css';
import Card from '../card';

// CSS Module styles for the slideshow.
import styles from './card-slider.module.scss';

export default class CardSlider extends Component {
  constructor() {
    super();

    this.state = {
      currentSlide: 0
    };
  }

  render() {
    // Settings for react-slick.
    const settings = {
      adaptiveHeight: true,
      className: styles.slideshow,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      afterChange: (index) => {
        this.setState({ currentSlide: index });
      }
    };
    const imgSizes = '(max-width: 576px) 98vw, (max-width: 795px) 90vw, 344px';
    return (
      <Slider
        className={styles.cardSlider}
        ref={(slider) => (this.slider = slider)}
        {...settings}
      >
        {this.props.cards.map((card, index) => {
          return (
            <Card
              key={`slider-card-${card.id}`}
              title={card.title}
              className={`${
                index === this.state.currentSlide
                  ? styles.activeCard
                  : styles.inActiveCard
              }`}
              image={card.largeImage}
              tag={card.tag}
              parentTag={card.parentTag}
              date={card.dateShort}
              summary={card.summary}
              url={card.url}
              type={card.type}
              srcset={card.srcSet.srcs.ratio16x9}
              sizes={imgSizes}
            />
          );
        })}
      </Slider>
    );
  }
}

CardSlider.propTypes = {
  cards: PropTypes.array.isRequired
};
