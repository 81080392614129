import { useStaticQuery, graphql } from 'gatsby';

export const useFeedbackFormData = () => {
  const { nodePage } = useStaticQuery(
    graphql`
      query FeedbackFormData {
        nodePage(id: { eq: "72a5c2f9-5627-55ce-ac03-bdeb6a11d08a" }) {
          title
          body {
            processed
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const feedbackForm = {
    title: nodePage?.title,
    body: nodePage?.body?.processed
  };

  return feedbackForm;
};
