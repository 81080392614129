import React, { useState, useEffect } from 'react';
import CardSlider from '../card-slider';
import Card from '../card';
import Hero from '../hero';
import CardList from '../card-list';
import PromotedPage from '../promoted-page';

// Grab the component data.
import { useEventsData } from '../../hooks/use-events-data';
import { useNewsData } from '../../hooks/use-news-data';
import { usePromotionsData } from '../../hooks/use-promotions-data';
import { usePromotedPageData } from '../../hooks/use-promoted-page-data';
import { useTallPromotedData } from '../../hooks/use-tall-promoted-data';
import { useFeedbackFormData } from '../../hooks/use-feedback-form-data';
import { useSuggestionFormData } from '../../hooks/use-suggestion-form-data';
import { useHomepagePromo1Data } from '../../hooks/use-homepage-promo-1-data';
import { useHomepagePromo2Data } from '../../hooks/use-homepage-promo-2-data';
import { useHomepagePromo3Data } from '../../hooks/use-homepage-promo-3-data';
import { useCityEventsData } from '../../hooks/use-city-events-data';
import { useProgramsData } from '../../hooks/use-programs-data';
import { useDocumentsData } from '../../hooks/use-documents-data';
import styles from './grid.module.scss';
import TallPromotedPage from '../tall-promoted-page/tall-promoted-page';

const Grid = () => {
  const events = useEventsData();
  const news = useNewsData();
  const promotions = usePromotionsData();
  const promotedPage = usePromotedPageData();
  const tallPromoted = useTallPromotedData();
  const feedbackForm = useFeedbackFormData();
  const suggestionForm = useSuggestionFormData();
  const homepage1Promotion = useHomepagePromo1Data();
  const homepage2Promotion = useHomepagePromo2Data();
  const homepage3Promotion = useHomepagePromo3Data();
  const cityEvents = useCityEventsData();
  const programs = useProgramsData();
  const documents = useDocumentsData();
  const allHomepagePromos = [
    homepage1Promotion,
    homepage2Promotion,
    homepage3Promotion
  ];

  // Rerversing the order of the promotions array for Hero
  // to put newest item first.
  const promotionsReverse = promotions.reverse();

  // These sizes are the same for all Card images.
  const eventImgSizes =
    '(max-width: 576px) 98vw, (max-width: 795px) 90vw, 344px';

  const [isMobile, setCardSlider] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 796) {
      setCardSlider(true);
    } else {
      setCardSlider(false);
    }
  };

  // Displays the Card Slider if viewport width is mobile.
  useEffect(() => {
    setCardSlider(window.innerWidth < 796);
    window.addEventListener('resize', handleResize);

    // Cleanup the function after the component is unmounted.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.grid}>
      <div className={styles.gridContainer}>
        <div className={styles.gridItem1}>
          <Hero promotions={promotionsReverse} />
        </div>
        <div className={styles.gridItem2}>
          {isMobile ? (
            <CardSlider cards={events} />
          ) : (
              <CardList
                cards={news}
                heading="Latest News"
                moreText="More News"
                moreLink="/news"
              />
            )}
        </div>
        <div className={styles.gridItem3}>
          <Card
            title={events[0]?.title}
            image={events[0]?.largeImage}
            srcset={events[0]?.srcSet?.srcs?.ratio16x9}
            sizes={eventImgSizes}
            tag={events[0]?.tag}
            type={events[0]?.type}
            parentTag={events[0]?.parentTag}
            date={events[0]?.dateShort}
            summary={events[0]?.summary}
            url={events[0]?.url}
          />
        </div>
        <div className={styles.gridItem4}>
          <TallPromotedPage page={tallPromoted} />
        </div>
        <div className={styles.gridItem5}>
          {isMobile ? (
            <CardSlider cards={cityEvents} />
          ) : (
              <Card
                title={events[1]?.title}
                type={events[1]?.type}
                image={events[1]?.largeImage}
                srcset={events[1]?.srcSet?.srcs?.ratio16x9}
                parentTag={events[1]?.parentTag}
                sizes={eventImgSizes}
                tag={events[1]?.tag}
                date={events[1]?.dateShort}
                summary={events[1]?.summary}
                url={events[1]?.url}
                className={styles.cardSliderCard}
              />
            )}
        </div>
        <div className={styles.gridItem6}>
          <Card
            type={events[2]?.type}
            title={events[2]?.title}
            image={events[2]?.largeImage}
            srcset={events[2]?.srcSet?.srcs?.ratio16x9}
            sizes={eventImgSizes}
            tag={events[2]?.tag}
            parentTag={events[2]?.parentTag}
            date={events[2]?.dateShort}
            summary={events[2]?.summary}
            url={events[2]?.url}
          />
        </div>
        <div className={styles.gridItem7}>
          <CardList
            className={styles.documentsList}
            cards={documents.slice(0, 7)}
            heading="Recent Documents"
            moreText="View More"
            moreLink="/documents"
            type="Documents"
          />
        </div>
        <div className={styles.gridItem8}>
          <CardList
            cards={programs}
            heading="Recreation Programs"
            moreText="More Events"
            moreLink="/events"
          />
        </div>
        <div className={styles.gridItem9}>
          <PromotedPage page={feedbackForm} dangerouslyRender={true} />
        </div>
        <div className={styles.gridItem10}>
          {isMobile ? (
            <CardSlider cards={allHomepagePromos} />
          ) : (
              <Card
                type={homepage1Promotion.type}
                image={homepage1Promotion.image}
                title={homepage1Promotion.title}
                tag={homepage1Promotion.tag}
                parentTag={homepage1Promotion.parentTag}
                srcset={homepage1Promotion?.srcSet?.srcs?.ratio16x9}
                sizes={eventImgSizes}
                summary={homepage1Promotion.summary}
                url={homepage1Promotion.url}
              />
            )}
        </div>
        <div className={styles.gridItem11}>
          <Card
            type={homepage2Promotion.type}
            image={homepage2Promotion.image}
            title={homepage2Promotion.title}
            tag={homepage2Promotion.tag}
            parentTag={homepage2Promotion.parentTag}
            srcset={homepage2Promotion?.srcSet?.srcs?.ratio16x9}
            sizes={eventImgSizes}
            summary={homepage2Promotion.summary}
            url={homepage2Promotion.url}
          />
        </div>
        <div className={styles.gridItem12}>
          <Card
            type={homepage3Promotion.type}
            image={homepage3Promotion.image}
            title={homepage3Promotion.title}
            tag={homepage3Promotion.tag}
            parentTag={homepage3Promotion.parentTag}
            srcset={homepage3Promotion?.srcSet?.srcs?.ratio16x9}
            sizes={eventImgSizes}
            summary={homepage3Promotion.summary}
            url={homepage3Promotion.url}
          />
        </div>
        <div className={styles.gridItem13}>
          <CardList
            cards={cityEvents}
            heading="Events at City Springs"
            moreText="More Events"
            moreLink="/events"
          />
        </div>
        <div className={styles.gridItem14}>
          <PromotedPage page={promotedPage} />
        </div>
        <div className={styles.gridItem15}>
          <PromotedPage page={suggestionForm} dangerouslyRender={true} />
        </div>
      </div>
    </div>
  );
};
export default Grid;
